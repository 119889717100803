import React, {useContext, useRef} from "react"
import {Field, Form, Formik, FormikHelpers} from "formik"
import PropTypes from "prop-types"
import SearchContext from "../../context/SearchContext"
import {useRouter} from "next/router"
import {useTranslation} from "next-i18next"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import AnalyticsContext from "../../context/AnalyticsContext"
import {X} from "react-bootstrap-icons"
import Image from "next/image"
import urlParams from "../../utility/UrlParams"
import {filterUrl} from "../filter/FilterUrl"

interface Values {
	searchTerm: string;
}

const SearchField = ({onStartTyping, onType, onEndTyping, className}) => {
	const {isOn, setValue, toggle, termValue, setTermValue, value} = useContext(SearchContext)
	const {reactGA} = useContext(AnalyticsContext)
	const {t} = useTranslation("search")

	const errors = {}
	const touched = {}

	const timeout = useRef(null)
	let onStartTypingSent = false

	const searchField = useRef(null)

	const router = useRouter()

	const handleOnChange = value => {
		if (timeout) {
			clearTimeout(timeout.current)
		}

		if (!onStartTypingSent) {
			onStartTypingSent = true
			onStartTyping()
		}

		timeout.current = setTimeout(() => {
			onStartTypingSent = false
			onEndTyping(value)
		}, 500)

		if (onType) {
			onType(value)
		}

		setValue(value)
	}

	const handlePressEnterToSaveSearchValue = e => {
		toggle(true)
		if (e.code === "Enter") {
			router.push(filterUrl({[urlParams.searchValue]: value, page: "-"}, null, null, t))
		}
	}

	// Put cursor in the end of input string
	const handleFocus = e => {
		reactGA?.event({
			category: gaCategories.search,
			action: gaEvents.searchTool,
			label: gaEvents.searchTool,
			nonInteraction: false
		})
		return e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)
	}

	return (
		<Formik
			initialValues={{
				searchTerm: "",
				locale: router.locale
			}}
			onSubmit={(
				values: Values,
				{setSubmitting}: FormikHelpers<Values>
			) => {
				onEndTyping(values.searchTerm)

				// Log event
				reactGA?.event({
					category: gaCategories.search,
					action: gaEvents.search,
					label: values.searchTerm,
					nonInteraction: false
				})
				setSubmitting(false)
			}}
		>{() =>
				<Form onKeyDown={handlePressEnterToSaveSearchValue} className={"position-relative d-flex justify-items-center"}>
					{termValue || isOn ? <button className={"position-absolute btn top-0 end-0 btn"} onClick={() => {
						router.push(filterUrl({[urlParams.searchValue]: value, page: "-"}, null, null, t))
						setTermValue("")
						setValue(null)
						toggle(false)
					}}>
						<X/>
					</button> :
						<div className={"position-absolute"} style={{top: 7, right: 16}}>
							<Image src="/assets/icons/search-icon.svg" width={24} height={24} alt="search icons"/>
						</div>}
					<Field value={termValue} onFocus={handleFocus} onChange={e => {
						setTermValue(e.target.value)
						handleOnChange(e.target.value)
					}} placeholder={"Durchsuche MySofaPlan"} innerRef={searchField} className={className || " w-100 form-control-lg border-0 bg-light search-field"} name={"searchTerm"} errors={errors} touched={touched}
					/>
				</Form>
			}
		</Formik>
	)
}

SearchField.propTypes = {
	onStartTyping: PropTypes.func,
	onType: PropTypes.func,
	onEndTyping: PropTypes.func,
	className: PropTypes.string
}

export default SearchField
